import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LevelSelectionService {

  onAdvertiserSelected: BehaviorSubject<any>;
  onCampaignSelected: BehaviorSubject<any>;
  onKeywordSelected: BehaviorSubject<any>;
  onAdvertisersUpdated: Subject<any>;
  onCampaignsUpdated: Subject<any>;
  constructor() {
    this.onAdvertiserSelected = new BehaviorSubject(null);
    this.onCampaignSelected = new BehaviorSubject(null);
    this.onKeywordSelected = new BehaviorSubject(null);
    this.onAdvertisersUpdated = new Subject();
    this.onCampaignsUpdated = new Subject();
  }
}
