import { Component, OnInit } from '@angular/core';
import {LevelSelectionService} from '../../_services/level-selection.service';
import {CommonAuthService} from '../../auth/common-auth.service';

export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  childMenu: Array<object>;
}

export const ROUTES: RouteInfo[] = [
  {
    path: '/clients', title: 'Clients', icon: 'nc-spaceship', class: '',
    childMenu: null
  },
  {
    path: '/advertisers', title: 'Advertisers', icon: 'nc-box', class: '',
    childMenu: null
  },
  {
    path: '', title: 'Campaigns', icon: 'nc-user-run', class: '',
    childMenu: [
      {path: '/campaigns', title: 'Search Campaigns', icon: 'nc-badge', class: ''},
      // {path: '/campaigns/keywords', title: 'Campaign Keywords', icon: 'nc-badge', class: ''},
      {path: '/campaigns/create', title: 'New Campaign', icon: 'nc-image', class: ''}
    ]
  },
  {
    path: '/custom-reports', title: 'Custom Reports', icon: 'nc-box', class: '',
    childMenu: null
  },
  {
    path: '/settings', title: 'Settings', icon: 'nc-box', class: '',
    childMenu: null
  },
  // {
  //   path: '/dashboard', title: 'New Campaign', icon: 'nc-image', class: '',
  //   childMenu: null
  // },


  // {
  //   path: '/proposals', title: 'Proposals', icon: 'nc-send', class: '',
  //   childMenu: null
  // },
  // {
  //   path: '', title: 'Manage', icon: 'nc-user-run', class: '',
  //   childMenu: [
  //     {path: '/users', title: 'Users', icon: 'nc-single-02', class: ''},
  //     {path: '/agencies', title: 'Agencies', icon: 'nc-single-02', class: ''},
  //   ]
  // }
];

// [
//     { path: '/users',         title: 'User List',         icon:'nc-bank',       class: '' },
//     { path: '/dashboard',     title: 'Dashboard',         icon:'nc-bank',       class: '' },
//     { path: '/icons',         title: 'Icons',             icon:'nc-diamond',    class: '' },
//     { path: '/maps',          title: 'Maps',              icon:'nc-pin-3',      class: '' },
//     { path: '/notifications', title: 'Notifications',     icon:'nc-bell-55',    class: '' },
//     { path: '/user',          title: 'User Profile',      icon:'nc-single-02',  class: '' },
//     { path: '/table',         title: 'Table List',        icon:'nc-tile-56',    class: '' },
//     { path: '/typography',    title: 'Typography',        icon:'nc-caps-small', class: '' }
// ];

@Component({
  moduleId: module.id,
  selector: 'sidebar-cmp',
  templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    advertiserSelected = false;
    campaignSelected = false;
    campaignSelectedId = '';
    currentUser;
    constructor(private levelService: LevelSelectionService, public auth: CommonAuthService) {
      this.currentUser = this.auth.currentUserValue;
    }
    ngOnInit() {
      this.menuItems = ROUTES.filter(menuItem => menuItem);
      console.log(this.menuItems);
      this.levelService.onAdvertiserSelected.subscribe(
        advertiser => {
          console.log(advertiser);
          this.advertiserSelected = advertiser !== null;
        }
      );
      this.levelService.onCampaignSelected.subscribe(
        campaign => {
          console.log('Sidebar Campaign: ', campaign);
          this.campaignSelected = campaign !== null;
          this.campaignSelectedId = campaign;
        }
      );
    }
}
