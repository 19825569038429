import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-grid-columns-customization',
  templateUrl: './grid-columns-customization.component.html',
  styleUrls: ['./grid-columns-customization.component.scss']
})
export class GridColumnsCustomizationComponent implements OnInit {
  ngLoading = false;
  columnsForm: FormGroup;
  currentColumns: Array<any> = [];
  gridColumns: Array<{text: string, id: string, child: Array<{text: string, id: string}>}> = [];

  constructor(
    private fb: FormBuilder,
    public matDialogRef: MatDialogRef<GridColumnsCustomizationComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public dialog: MatDialog,
  ) {
    if (typeof this._data.gridColumns !== 'undefined' && this._data.gridColumns !== null) {
      this.gridColumns = this._data.gridColumns;
    }
    if (typeof this._data.currentColumns !== 'undefined' && this._data.currentColumns !== null) {
      this.currentColumns = this._data.currentColumns;
    }
  }

  ngOnInit() {
    if (this.gridColumns.length > 0) {
      this.columnsForm = this.fb.group({});
      for (const columnGroup of this.gridColumns) {
        const columnForm = this.fb.group({});
        for (const column of columnGroup.child) {
          columnForm.addControl(column.id, this.fb.control(column.id));
        }
        this.columnsForm.addControl(columnGroup.id, columnForm);
      }
    }

    // this.columnsForm = this.fb.group({
    //   auctions: this.fb.group({
    //     impressionsWon: ['impressionsWon'],
    //     winRate: ['winRate'],
    //     totalEffectiveCPM: ['totalEffectiveCPM'],
    //     effectiveCPMOnAdvertiserSpend: ['effectiveCPMOnAdvertiserSpend'],
    //     mediaEffectiveCPM: ['mediaEffectiveCPM'],
    //     dataEffectiveCPM: ['dataEffectiveCPM'],
    //   }),
    //   audio_video: this.fb.group({
    //     videoStarted: ['videoStarted'],
    //     videoFirstQuartileReached: ['videoFirstQuartileReached'],
    //     videoMidpointReached: ['videoMidpointReached'],
    //     videoThirdQuartileReached: ['videoThirdQuartileReached'],
    //     videoCompleted: ['videoCompleted'],
    //     videoCompletionRate: ['videoCompletionRate'],
    //     effectiveCPCVOnAdvertiserSpend: ['effectiveCPCVOnAdvertiserSpend'],
    //     effectiveCPCV: ['effectiveCPCV'],
    //   }),
    //   budget: this.fb.group({
    //     totalSpend: ['totalSpend'],
    //     impliedBid: ['impliedBid'],
    //     dailyTotalSpendTarget: ['dailyTotalSpendTarget'],
    //     budget: ['budget'],
    //     gboDailyTotalSpendTarget: ['gboDailyTotalSpendTarget'],
    //     advertiserSpend: ['advertiserSpend'],
    //     auctionsSpend: ['auctionsSpend'],
    //     dataSpend: ['dataSpend'],
    //   }),
    //   clicks: this.fb.group({
    //     clicks: ['clicks'],
    //     effectiveCPCOnAdvertiserSpend: ['effectiveCPCOnAdvertiserSpend'],
    //     costPerClick: ['costPerClick'],
    //     clickthruRate: ['clickthruRate'],
    //     profitPerClick: ['profitPerClick'],
    //   }),
    //   companion_ads: this.fb.group({
    //     companionImpressions: ['companionImpressions'],
    //     companionClicks: ['companionClicks'],
    //     companionConversions: ['companionConversions'],
    //     companionOfferRevenue: ['companionOfferRevenue'],
    //   }),
    //   conversions: this.fb.group({
    //     totalConversions: ['totalConversions'],
    //     clickThroughConversions: ['clickThroughConversions'],
    //     viewthruConversions: ['viewthruConversions'],
    //     effectiveCPAOnAdvertiserSpend: ['effectiveCPAOnAdvertiserSpend'],
    //     effectiveCPA: ['effectiveCPA'],
    //     ctcEffectiveCPA: ['ctcEffectiveCPA'],
    //     clickCVR: ['clickCVR'],
    //     viewCVR: ['viewCVR'],
    //     totalCVRM: ['totalCVRM'],
    //   }),
    //   extras: this.fb.group({
    //     id: ['id'],
    //     type: ['type'],
    //     start: ['start'],
    //     end: ['end'],
    //     kpiType: ['kpiType'],
    //     kpiValue: ['kpiValue'],
    //   }),
    //   primary_conversions: this.fb.group({
    //     conversions: ['conversions'],
    //     primaryCTC: ['primaryCTC'],
    //     primaryVTC: ['primaryVTC'],
    //     primaryEffectiveCPASpend: ['primaryEffectiveCPASpend'],
    //     primaryEffectiveCPAConversion: ['primaryEffectiveCPAConversion'],
    //     primaryEffectiveCPACTC: ['primaryEffectiveCPACTC'],
    //   }),
    //   revenues: this.fb.group({
    //     revenue: ['revenue'],
    //     ctcRevenue: ['ctcRevenue'],
    //     vtcRevenue: ['vtcRevenue'],
    //     revenuePerMille: ['revenuePerMille'],
    //     clickRPM: ['clickRPM'],
    //     returnOnAdSpend: ['returnOnAdSpend'],
    //   }),
    //   viewability: this.fb.group({
    //     eligibleImpressions: ['eligibleImpressions'],
    //     measuredImpressions: ['measuredImpressions'],
    //     viewableImpressions: ['viewableImpressions'],
    //     measuredRate: ['measuredRate'],
    //     viewableRate: ['viewableRate'],
    //     eligibleSpend: ['eligibleSpend'],
    //     eligibleVCPM: ['eligibleVCPM'],
    //   })
    // });
  }

  closeModal() {
    // @ts-ignore
    $('.grid-columns-customization').modal('hide');
  }

  scroll(elem: HTMLElement): void {
    elem.scrollIntoView();
  }

  scrollTest(elem: string): void {
    const el = document.getElementById(elem);
    el.scrollIntoView();
  }

  inCurrentColumns(column: string): boolean {
    return this.currentColumns.indexOf(column) !== -1;
  }

  allSelected(section: string): boolean {
    const sectionColumns = Object.keys((this.columnsForm.get(section) as FormGroup).controls);
    let existing = 0;
    for (const column of sectionColumns) {
      if (this.currentColumns.indexOf(column) !== -1) {
        existing++;
      }
    }
    return sectionColumns.length === existing;
  }

  partiallySelected(section: string): boolean {
    const sectionColumns = Object.keys((this.columnsForm.get(section) as FormGroup).controls);
    let existing = 0;
    for (const column of sectionColumns) {
      if (this.currentColumns.indexOf(column) !== -1) {
        existing++;
      }
    }
    return (sectionColumns.length > existing && existing > 0);
  }

  selectAll(section: any, checked: boolean): void {
    const sectionColumns = Object.keys((this.columnsForm.get(section) as FormGroup).controls);
    for (const column of sectionColumns) {
      if (this.currentColumns.indexOf(column) === -1 && checked) {
        this.currentColumns.push(column);
      } else if (this.currentColumns.indexOf(column) !== -1 && !checked) {
        this.currentColumns.splice(this.currentColumns.indexOf(column), 1);
      }
    }
  }

  checkboxEvent(event: any): void {
    if (event.checked) {
      this.currentColumns.push(event.source.name);
    } else {
      this.currentColumns.splice(this.currentColumns.indexOf(event.source.name), 1);
    }
  }

  setCustomCustomization(): void {
    this.matDialogRef.close({columns: this.currentColumns});
  }
}
