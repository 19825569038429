import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {CommonAuthService} from '../../auth/common-auth.service';
import {UserRestService} from '../../admin/users/user-rest.service';
import {environment} from '../../../environments/environment';


@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
  public loading: boolean;
  public envData = environment;
  constructor(private router: Router, private authenticationService: CommonAuthService, private userService: UserRestService) {
  }
  ngOnInit() {
    this.validateTermsAcceptance();
  }

  validateTermsAcceptance() {
    const user = this.authenticationService.currentUserValue;
    console.log('Entered...');
    if (typeof user !== 'undefined' && user !== null) {
      console.log('Entered 2...');
      if (typeof user.accepted_terms_date === 'undefined' || user.accepted_terms_date === null || user.accepted_terms_date === '') {
        console.log('Entered 3...');
        // @ts-ignore
        $('.accept-terms-modal').modal({backdrop: 'static', keyboard: false});
        // @ts-ignore
        $('.accept-terms-modal').modal('show');
      }
    }
  }

  sendAcceptance() {
    this.loading = true;
    this.userService.setTermsAcceptance().subscribe(response => {
      console.log(response)
      // @ts-ignore
      localStorage.setItem('user', JSON.stringify(response.response.user));
      // @ts-ignore
      localStorage.setItem('user2', JSON.stringify(response.response.user));
      // this.authenticationService.setCustomUser(response.response.user);
      window.location.reload();
      this.loading = false;
    }, error => {
      this.loading = false;
      console.log(error)
    });
  }

  closeAcceptance() {
    // @ts-ignore
    $('.accept-terms-modal').modal('hide');
    // @ts-ignore
    $('.closed-terms-modal').modal({backdrop: 'static', keyboard: false});
    // @ts-ignore
    $('.closed-terms-modal').modal('show');
  }

  backToAcceptance() {
    // @ts-ignore
    $('.closed-terms-modal').modal('hide');
    // @ts-ignore
    $('.accept-terms-modal').modal({backdrop: 'static', keyboard: false});
    // @ts-ignore
    $('.accept-terms-modal').modal('show');
  }
}
