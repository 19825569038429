import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdvertiserRestService {
  campaigns: Array<{ id: number, name: string, service_dma: string, phone: string,
    subcategory: string, state: string, city: string, zip_code: string, created_at: string }> = [];

  constructor(private http: HttpClient) {
  }

  getadvertisers(): Observable<any> {
    return this.http.get(`${environment.apiUrl}advertiser-list`);
  }

  getLocalIQAdvertisers(): Observable<any> {
    return this.http.get(`${environment.apiUrl}advertiser-list/localiq`);
  }

  getCategories(): Observable<any> {
    return this.http.get(`${environment.apiUrl}categories`);
  }

  editadvertiser(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}advertiser-list/` + id);
  }

  updateadvertiser(form, id): Observable<any> {
    return this.http.put(`${environment.apiUrl}advertiser-list/` + id, form.value);
  }

  storeadvertiser(form): Observable<any> {
    return this.http.post(`${environment.apiUrl}advertiser-list`, form.value);
  }

  deleteadvertiser(id): Observable<any> {
    return this.http.delete(`${environment.apiUrl}advertiser-list/` + id);
  }
}
