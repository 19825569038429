import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import {LicenseManager} from '@ag-grid-enterprise/core';
// tslint:disable-next-line:max-line-length
LicenseManager.setLicenseKey('CompanyName=Gale Force Digital Technologies, Inc,LicensedGroup=Gale Force Digital Technologies, Inc.,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-035592,SupportServicesEnd=7_December_2023_[v2]_MTcwMTkwNzIwMDAwMA==599e72f289c632385dda190c41d1d9c5');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
