import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {of, Observable, BehaviorSubject} from 'rxjs';
import {environment} from '../../environments/environment';
import {map, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonAuthService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  loggedIn = false;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('user')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.isAuthonticated();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  registerUser(form: any) {
    console.log(form.value);
    return this.http.post(`${environment.apiUrl}register`, form.value);
  }

  isAuthonticated() {
    const promise = new Promise(
      (resolve, reject) => {
        setTimeout(() => {
          const t = localStorage.getItem('token');
          if (t) {
            this.loggedIn = true;
            resolve(this.loggedIn);
          } else {
            this.loggedIn = false;
            reject();
          }
        }, 800);
      });
    return promise;
  }

  logIn(form: any): Observable<any> {
    // return this.http.post('http://adforce.test/api/login', form.value);
    return this.http.post<any>(`${environment.apiUrl}login`, form.value)
      .pipe(map(response => {
        console.log(response);
        if (response && response.token && response.user) {
          localStorage.removeItem('token');
          localStorage.setItem('token', response.token);
          localStorage.setItem('user', JSON.stringify(response.user));
          this.currentUserSubject.next(response.user);
        }
        return response;
      }));
  }

  setCustomUser(user: any) {
    this.currentUserSubject.next(user);
  }

  logout(token: any = ''): Observable<any> {
    if ( this.loggedIn ) {
      this.loggedIn = false;
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      this.currentUserSubject.next(null);
      return this.http.post(`${environment.apiUrl}logout`, {'token': token});
    }
    return this.http.post(`${environment.apiUrl}logout`, {'token': token});
    // return result;
  }

  getByToken(token: string) {
    return this.http.get<any>(`${environment.apiUrl}` + 'user').pipe(
      tap(_ => console.log('fetched getByToken'))
    );
  }

  resetPassword(email: string, redirectUrl = '') {
    return this.http.post<any>(`${environment.apiUrl}password/forgot`, { email: email, redirectUrl: redirectUrl})
      .pipe(map(response => {
        // login successful if there's a jwt token in the response
        console.log(response);
        return response;
      }));
  }

  // tslint:disable-next-line:variable-name
  newPassword(password: string, confirmPassword: string, token: string) {
    // tslint:disable-next-line:max-line-length
    return this.http.put<any>(`${environment.apiUrl}password/reset`, { password: password, password_confirmation: confirmPassword, token: token })
      .pipe(map(response => {
        // login successful if there's a jwt token in the response
        console.log(response);
        return response;
      }));
  }

  updatePassword(data: any, id: bigint) {
    return this.http.put(`${environment.apiUrl}` + 'users' + '/' + id + '/change-password', data, this.httpOptions)
      .pipe(
        tap(_ => console.log('updatePassword'))
      ) as Observable<any>;
  }

}
