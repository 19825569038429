import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CampaignRestService {
  campaigns: Array<{ id: number, name: string, email: string }> = [];

  constructor(private http: HttpClient) {
  }

  getcampaigns(): Observable<any> {
    return this.http.get(`${environment.apiUrl}campaign-list`);
  }

  editcampaign(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}campaign-list/` + id);
  }

  updatecampaign(form, id): Observable<any> {
    return this.http.put(`${environment.apiUrl}campaign-list/` + id, form.value);
  }

  storecampaign(form): Observable<any> {
    return this.http.post(`${environment.apiUrl}campaign-list`, form.value);
  }

  deletecampaign(id): Observable<any> {
    return this.http.delete(`${environment.apiUrl}campaign-list/` + id);
  }

  getOverviewCampaigns(advertiser_platform_id: string = null, advertiser_platform_type = 'gf') {
    return this.http.get(`${environment.apiUrl}campaigns-overview-localiq/` + advertiser_platform_type + '/' + (advertiser_platform_id !== null ? advertiser_platform_id : ''));
  }

  getCampaignsLocalIQ(advertiser_platform_id: string = null, advertiser_platform_type = 'gf', params: any = {}) {
    // @ts-ignore
    return this.http.get(`${environment.apiUrl}campaigns-localiq/` + advertiser_platform_type + '/' + (advertiser_platform_id !== null ? advertiser_platform_id : '') + (Object.entries(params).length > 0 ? ('?' + Object.entries(params).map(i => i.join('=')).join('&')) : ''));
  }

  getGoogleCampaignsLocalIQ(advertiser_platform_id: string = null, advertiser_platform_type = 'gf', params: any = {}) {
    // @ts-ignore
    return this.http.get(`${environment.apiUrl}google-campaigns-localiq/` + advertiser_platform_type + '/' + (advertiser_platform_id !== null ? advertiser_platform_id : '') + (Object.entries(params).length > 0 ? ('?' + Object.entries(params).map(i => i.join('=')).join('&')) : ''));
  }

  getKeywordsLocalIQ(advertiser_platform_id: string = null, advertiser_platform_type = 'gf', params: any = {}) {
    // @ts-ignore
    return this.http.get(`${environment.apiUrl}keywords-localiq/` + advertiser_platform_type + '/' + (advertiser_platform_id !== null ? advertiser_platform_id : '') + (Object.entries(params).length > 0 ? ('?' + Object.entries(params).map(i => i.join('=')).join('&')) : ''));
  }

  getGoogleKeywordsLocalIQ(advertiser_platform_id: string = null, advertiser_platform_type = 'gf', params: any = {}) {
    // @ts-ignore
    return this.http.get(`${environment.apiUrl}google-keywords-localiq/` + advertiser_platform_type + '/' + (advertiser_platform_id !== null ? advertiser_platform_id : '') + (Object.entries(params).length > 0 ? ('?' + Object.entries(params).map(i => i.join('=')).join('&')) : ''));
  }
}
