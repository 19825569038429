import { Component } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CommonAuthService} from './auth/common-auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  constructor(private router: Router, private route: ActivatedRoute, private userService: CommonAuthService) {
    console.log('routes: ', router, this.userService.loggedIn);
    this.route.queryParams.subscribe(params => {
      console.log(params);
      if (typeof params['single_sign_on_code'] !== 'undefined' && params['single_sign_on_code'] !== null && params['single_sign_on_code'] !== '') {
        const oldToken = localStorage.getItem('token');
        localStorage.setItem('token', params['single_sign_on_code']);
        this.userService.getByToken(params['single_sign_on_code']).subscribe(
          response => {
            console.log(response)
            this.router.navigate(['/dashboard']);
          }, error => {
            console.log(error)
            localStorage.setItem('token', '');
          }
        )
      }
    });
  }
}

