import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpHeaders } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {AlertService} from '../_services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(public alert: AlertService) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // note add this header for CORS pass
    const token = localStorage.getItem('token');
    let user = JSON.parse(localStorage.getItem('user'));
    if (typeof user !== 'undefined' && user !== null && typeof user['role'] !== 'undefined' && user['role'] !== null
      && user['role'] === 'admin' && typeof user['email'] !== 'undefined' && user['email'] !== null
      && user['email'] === 'accounting@thinkstrategic.com' && req.method !== 'GET') {
      this.alert.alert('error', 'You don\'t have enough permissions for this action.');
      return throwError({error: {errors: []}});
    }
    let headers = null;

    if (token) {
      headers = new HttpHeaders({
        // 'Content-Type': 'application/form-data; charset=UTF-8, application/json',
        'Authorization': `Bearer ${token}`,
      });
    } else {
      headers = new HttpHeaders({
        'Content-Type': 'application/form-data; charset=UTF-8, application/json'
      });
    }
    const requestChange = req.clone({headers});
    // console.log(requestChange);
    return next.handle(requestChange);
  }
}
