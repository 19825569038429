import { Component, OnInit, Renderer, ViewChild, ElementRef } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Router } from '@angular/router';
import { Location} from '@angular/common';
import { CommonAuthService } from 'app/auth/common-auth.service';
import {LevelSelectionService} from '../../_services/level-selection.service';
import {response} from 'express';
import {AdvertiserRestService} from '../../pages/advertisers/advertiser-rest.service';
import {CampaignService} from '../../pages/campaigns/campaign.service';
import {CampaignRestService} from '../../pages/campaigns/campaign-rest.service';

@Component({
    moduleId: module.id,
    selector: 'navbar-cmp',
    templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit {
  private listTitles: any[];
  location: Location;
  private nativeElement: Node;
  private toggleButton;
  private sidebarVisible: boolean;

  public isCollapsed = true;
  @ViewChild("navbar-cmp", {static: false}) button;
  platformId = '';
  platformType = 'gf';
  campaignId = '';
  advertisers = [];
  campaigns = [];
  loadingCampaigns = false;

  constructor(
    location: Location,
    private renderer: Renderer,
    private element: ElementRef,
    private router: Router,
    private auth: CommonAuthService,
    private levelService: LevelSelectionService,
    private adversiterService: AdvertiserRestService,
    private campaignService: CampaignRestService,
  ) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
  }

  ngOnInit() {
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    this.router.events.subscribe((event) => {
      this.sidebarClose();
    });
    this.getAdvertisers();
    this.levelService.onAdvertiserSelected.subscribe(
      advertiser => {
        console.log('this.levelService.onAdvertiserSelected: ', advertiser);
        this.platformId = advertiser !== null ? advertiser.platform_id : '';
        this.platformType = advertiser !== null ? advertiser.platform_type : '';
        this.levelService.onCampaignsUpdated.next();
      }
    );
    this.levelService.onAdvertisersUpdated.subscribe(
      data => {
        this.getAdvertisers();
      }
    );

    this.levelService.onCampaignSelected.subscribe(
      campaign => {
        console.log('this.levelService.onCampaignSelected: ', campaign);
        this.campaignId = campaign !== null ? campaign : '';
      }
    );
    this.levelService.onCampaignsUpdated.subscribe(
      data => {
        console.log('this.levelService.onCampaignsUpdated')
        this.getAdvertiserCampaigns();
      }
    );
  }

  logout() {
    const token = localStorage.getItem('token');
    this.auth.logout(token).subscribe(
      (resp) => {
        localStorage.clear();
        this.router.navigate(['/home/login']);
        console.log(resp);
      },
      (error) => {
        //this.router.navigate(['/home/login'])
      }
    );
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }
    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return 'Dashboard';
  }

  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);

    html.classList.add('nav-open');
    if (window.innerWidth < 991) {
      mainPanel.style.position = 'fixed';
    }
    this.sidebarVisible = true;
  };

  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    if (window.innerWidth < 991) {
      setTimeout(function () {
        mainPanel.style.position = '';
      }, 500);
    }
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
  };

  collapse() {
    this.isCollapsed = !this.isCollapsed;
    const navbar = document.getElementsByTagName('nav')[0];
    console.log(navbar);
    if (!this.isCollapsed) {
      navbar.classList.remove('navbar-transparent');
      navbar.classList.add('bg-white');
    } else {
      navbar.classList.add('navbar-transparent');
      navbar.classList.remove('bg-white');
    }

  }

  setSelectedAdvertiser(event: any) {
    console.log(event)
    this.platformId = event;
    const adv = this.advertisers.find(i => i.platform_id === event);
    this.platformType = adv.platform_type;
    this.levelService.onAdvertiserSelected.next(adv);
  }

  setSelectedCampaign(event: any) {
    console.log(event)
    this.campaignId = event;
    this.levelService.onCampaignSelected.next(event);
  }

  getAdvertiserCampaigns() {
    if (typeof this.platformType !== 'undefined' && this.platformType !== null && this.platformType !== '') {
      this.loadingCampaigns = true;
      this.campaignService.getOverviewCampaigns(this.platformId, this.platformType).subscribe(
        campaigns => {
          this.campaigns = campaigns['campaigns'];
          this.campaignId = '';
          this.loadingCampaigns = false;
        }
      )
    }
  }

  getAdvertisers() {
    this.adversiterService.getadvertisers().subscribe(
      advertisers => {
        this.advertisers = advertisers.advertiser;
      }, error => console.log('getAdvertisers', error)
    );
  }
}
