import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { WelcomeLayoutComponent } from './layouts/welcome-layout/welcome-layout.component';
import {TermsComponent} from './pages/terms/terms.component';
import {PrivacyPolicyComponent} from './pages/privacy-policy/privacy-policy.component';
import {PrivacyPolicyPrintComponent} from './pages/privacy-policy-print/privacy-policy-print.component';
import {TermsPrintComponent} from './pages/terms-print/terms-print.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home/login',
    pathMatch: 'full',
  }, {
    path: '',
    component: AdminLayoutComponent,
    children: [
        {
      path: '',
      loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
  }]}, {
    path: 'home',
    component: WelcomeLayoutComponent,
    children: [
        {
      path: '',
      loadChildren: './layouts/welcome-layout/welcome-layout.module#WelcomeLayoutModule'
  }]},
  {
    path: 'terms',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        component: TermsComponent,
      }]
  },
  {
    path: 'privacy-policy',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        component: PrivacyPolicyComponent,
      }]
  },
  {
    path: 'privacy-policy-print',
    component: PrivacyPolicyPrintComponent
  },
  {
    path: 'terms-print',
    component: TermsPrintComponent
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
]
