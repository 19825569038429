// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // apiUrl: 'http://clickforce.test/api/',
  // publicUrl: 'http://clickforce.test/',
  // appUrl: 'http://localhost:4200/'
  apiUrl: 'https://dev.api.cforce.galeforcedigital.com/api/',
  publicUrl: 'https://dev.api.cforce.galeforcedigital.com/',
  appUrl: 'https://dev.cforce.galeforcedigital.com/'
};
