import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { SidebarModule } from './shared/sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { FixedPluginModule} from './shared/fixedplugin/fixedplugin.module';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { WelcomeLayoutComponent } from './layouts/welcome-layout/welcome-layout.component';
import { NavbarComponent } from './shared/common/navbar/navbar.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './auth/auth-interceptor.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import {MatTabsModule} from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';
import {MatStepper, MatStepperModule} from '@angular/material/stepper';
import * as $ from 'jquery';
import {FilePondModule, registerPlugin} from 'ngx-filepond';

// import and register filepond file type validation plugin
import * as FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
registerPlugin(FilePondPluginFileValidateType);
// Import the plugin code
import * as FilePond from 'filepond';
import * as FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {PartnersComponent} from './pages/partners/partners.component';
import {AgGridModule} from 'ag-grid-angular';
import {NewClientComponent} from './modals/new-client/new-client.component';
import {NewUserComponent} from './modals/new-user/new-user.component';
import {ActionsCellRendererComponent} from './shared/actions-cell-renderer/actions-cell-renderer.component';
import {RangesDatepickerComponent} from './shared/ranges-datepicker/ranges-datepicker.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, SatNativeDateModule} from 'saturn-datepicker';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {NumberFormatCellRendererComponent} from './shared/cell-renderers/number-format-cell-renderer/number-format-cell-renderer.component';
import {TotalCellRendererComponent} from './shared/cell-renderers/total-cell-renderer/total-cell-renderer.component';
import {GridColumnsCustomizationComponent} from './shared/grid-columns-customization/grid-columns-customization.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {ErrorInterceptor} from './_helpers/error.interceptor';
import {ModuleRegistry} from 'ag-grid-community';
import {ExcelExportModule} from '@ag-grid-enterprise/excel-export';
import {TermsComponent} from './pages/terms/terms.component';
import {TermsPrintComponent} from './pages/terms-print/terms-print.component';
import {PrivacyPolicyComponent} from './pages/privacy-policy/privacy-policy.component';
import {PrivacyPolicyPrintComponent} from './pages/privacy-policy-print/privacy-policy-print.component';
// Register the plugin
FilePond.registerPlugin(FilePondPluginFileValidateSize);

ModuleRegistry.registerModules([
  // @ts-ignore
  ExcelExportModule
]);

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    WelcomeLayoutComponent,
    NavbarComponent,
    RangesDatepickerComponent,
    NumberFormatCellRendererComponent,
    TotalCellRendererComponent,
    GridColumnsCustomizationComponent,
    TermsComponent,
    TermsPrintComponent,
    PrivacyPolicyComponent,
    PrivacyPolicyPrintComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: false
    }),
    SidebarModule,
    NavbarModule,
    ToastrModule.forRoot(),
    FooterModule,
    FixedPluginModule,
    MatTabsModule,
    MatIconModule,
    MatStepperModule,
    FilePondModule,
    SweetAlert2Module.forRoot(),
    MatFormFieldModule,
    MatSelectModule,
    SatNativeDateModule,
    MatCheckboxModule,
    MatDialogModule
  ],
  entryComponents: [RangesDatepickerComponent, NumberFormatCellRendererComponent, TotalCellRendererComponent, GridColumnsCustomizationComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
